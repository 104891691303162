<template>
  <CRow>

    <CModal
        :title="
        submitType === 'Update'
          ? $lang.recruitments.viewSchedule
          : $lang.platform_ratting.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >


      <CCardBody>
        <div v-show="viewSchedules">
          <div><h4>Opportunity Information</h4>
            <div><b> Title </b> {{ opportunity.title }}</div>
          </div>
          <br>
          <div v-show="viewSchedules.agency"><h4>Agency(Company) Information</h4>
            <div v-show="viewSchedules.agency"><b> Agency </b> {{ viewSchedules.agency }}</div>
            <div v-show="viewSchedules.name"><b> User Name </b> {{ viewSchedules.name }}</div>
            <div v-show="viewSchedules.email"><b> Email</b> {{ viewSchedules.email }}</div>
          </div>
          <br>
          <div><b>Mode</b> : {{ viewSchedules.mode }}</div>
          <div v-show="viewSchedules.modeInfo"><b>Mode Info</b> : {{ viewSchedules.modeInfo }}</div>
          <div><b>Status</b> :
            <CBadge :color="statusBudget(viewSchedules.status)">{{ viewSchedules.status }}</CBadge>
          </div>
          <div><b>Remarks</b> : {{ viewSchedules.remarks }}</div>
          <div><b>Interview Date & Time</b> : {{ viewSchedules.interviewDate }}  {{ viewSchedules.interviewTime }}</div>
          <br>
          <div><h4>Template Information</h4>
            <div><b> Name</b> {{ template.name }}</div>
            <div><b> Round Name </b> {{ round.title }}</div>
          </div>
          <br>
          <div v-show="candidate"><h4>Candidate Information</h4>
            <div v-show="candidate.name"><b> Name</b> {{ candidate.name }}</div>
            <div v-show="candidate.email"><b> Email</b> {{ candidate.email }}</div>
          </div>
          <br>
          <div v-show="self.interviewers">
            <h4>Interviewers Information</h4>
            <div v-for="interviewer in self.interviewers">
              <div><b> Name </b> {{ interviewer.name }}</div>
              <div><b> Email</b> {{ interviewer.email }}</div>
            </div>
          </div>

          <div id="people">
            <div class="row row-sm">
              <div
                  v-for="(media, index) in mediaLists"
                  :key="media.id"
                  class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-2"
              >
                <div class="card card-file media">
                  <div class="card-file-thumb">
                    <i class="fa fa-file-word-o"></i>
                  </div>
                  <div class="card-body text-center">
                    <div v-if="media.mediaName === 'Image'">
                      <img
                          alt="Media"
                          :src="media.url"
                          height="98"
                          @click="open(media.url, media.type)"
                          style="cursor: pointer"
                          class="mx-auto d-block"
                      />
                      <template>
                        <CDropdown toggler-text="Actions">
                          <CDropdownItem :href="media.url">Download</CDropdownItem>
                          <CDropdownItem @click="openDetail(media)">Detail</CDropdownItem>
                        </CDropdown>
                      </template>
                    </div>
                    <div v-else-if="media.mediaName !== 'Image'">
                      <img
                          alt="Default Image"
                          :src="media.defaultImage"
                          class="mx-auto d-block"
                      />
                      <template>
                        <CDropdown toggler-text="Actions">
                          <CDropdownItem :href="media.url">Download</CDropdownItem>
                        </CDropdown>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button @click="btnHandleScroll()" class="col-12 btn btn-primary" v-if="btn_dis>0">
                  {{ $lang.notification.load_more }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </CCardBody>
    </CModal>

    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong> Candidate : {{ candidateName }} </strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'OpportunityOutSystemCandidateInterviewedList',self.objectId,self.candidateId)">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'OpportunityOutSystemCandidateInterviewedList',self.objectId,self.candidateId)">

            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>

            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.show"
                  v-on:click="viewInterview(data.row.id)"
                  v-if="checkPermission('list-opportunity')"
              >
                <i class="fas fa-file"></i>
              </CButton>
            </template>



          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>

import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  Recruitments
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "OutSystemCandidateInterviewScheduleList",
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      jobTitle: '',
      viewSchedules: [],
      candidate: [],
      opportunity: [],
      interviewers: [],
      mediaLists:[],
      round: [],
      template: [],
      candidateName: '',
      selectedColumns: [],
      iAmUser: "",
      userId: "",
      listUrl: "",
      list: [],
      experienceList: [],
      commissionTypeList: [],
      workTypeList: [],
      availabilityList: [],
      currencyList: [],
      jobTypeList: [],
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      largeModal: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      jobData: [],
      requirement: [],
      worktype: [],
      activePage: 1,
      module: Recruitments,
      filter: {},
      recruitments: {},
      columns: [
        "template",
        "round",
        "status",
        "remarks",
        "mode",
        "interviewDate",
        "interviewTime",
        "createdAt",
        "updatedAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          template: this.$lang.recruitments.table.candidates.interview.template,
          round: this.$lang.recruitments.table.candidates.interview.round,
          status: this.$lang.recruitments.table.candidates.interview.status,
          remarks: this.$lang.recruitments.table.candidates.interview.remarks,
          mode: this.$lang.recruitments.table.candidates.interview.mode,
          interviewDate: this.$lang.recruitments.table.candidates.interview.interviewDate,
          interviewTime: this.$lang.recruitments.table.candidates.interview.interviewTime,
          createdAt: this.$lang.recruitments.table.candidates.interview.createdAt,
          updatedAt: this.$lang.recruitments.table.candidates.interview.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [
          "firstName",
        ],
        filterable: [],
      },
    };
  },
  props: ['objectId', 'candidateId'],
  created() {
    let self = this;
    self.listUrl = "/opportunities/candidates/interview-schedules/out-system/" + this.objectId + '/' +this.candidateId;
    self.candidateName = localStorage.getItem('candidateName');
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false);
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    setAlert(value) {
      this.showAlert = value;
    },
    viewInterview(interviewScheduleId) {
      let self = this;
      self.submitType = "Update";
      self.editId = interviewScheduleId;
      axios
          .get("/opportunities/candidates/interview-schedules/" + interviewScheduleId).then((response) => {
        if (response) {
          self.viewSchedules = response.data.data;
          self.candidate = self.viewSchedules.candidate ?? self.viewSchedules.outSystemCandidate;
          self.interviewers = self.viewSchedules.interviewers;
          self.opportunity = self.viewSchedules.opportunity;
          self.template = self.viewSchedules.template;
          self.round = self.viewSchedules.rounds;
          self.mediaLists = self.viewSchedules.media;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
