<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>

              {{ jobTitle }}

            </strong>
            <back-to-list></back-to-list>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span
                @click="selected = 'OpportunityCandidateList'"
                v-if="checkPermission('list-opportunity')"
            >
              <CTab v-show="this.showTab" active>
                <template slot="title">
                  {{ $lang.recruitments.tab.candidates.tab.candidatesList }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'OppCandidateInterviewScheduleList'"
                v-if="checkPermission('list-opportunity') && selected==='OppCandidateInterviewScheduleList'"
            >
              <CTab>
                <template slot="title">
                   {{ $lang.recruitments.tab.candidates.tab.CandidateInterviewScheduleList }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'OpportunityOutSystemCandidateList'"
                v-if="checkPermission('list-opportunity')"
            >
              <CTab v-show="this.showTab">
                <template slot="title">
                  {{ $lang.recruitments.tab.candidates.tab.outSystemCandidatesList }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'OutSystemCandidateInterviewScheduleList'"
                v-if="checkPermission('list-opportunity') && selected==='OutSystemCandidateInterviewScheduleList'"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.recruitments.tab.candidates.tab.CandidateInterviewScheduleList }}
                </template>
              </CTab>
            </span>


          </CTabs>
          <component
              :is="selected"
              style="margin-top: 20px"
              v-bind:objectId="objectId"
              v-bind:candidateId="candidateId"
              @changeSelected="getTab($event)"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {Mixin} from "/src/mixins/index.js";
import OpportunityCandidateList from "./tab/OpportunityCandidateList";
import OpportunityOutSystemCandidateList from "./tab/OpportunityOutSystemCandidateList";
import OppCandidateInterviewScheduleList from "./tab/OppCandidateInterviewScheduleList";
import OutSystemCandidateInterviewScheduleList from "./tab/OutSystemCandidateInterviewScheduleList";
import BackToList from "../backToList.vue";
import store from "../../store/store";
import {serverBus} from "/src/main";

export default {
  name: "OpportunityCandidateListTab",
  mixins: [Mixin],
  components: {
    OpportunityCandidateList,
    OppCandidateInterviewScheduleList,
    BackToList,
    OpportunityOutSystemCandidateList,
    OutSystemCandidateInterviewScheduleList,
  },
  data() {
    return {
      showTab: '',
      jobTitle: "",
      userId: "",
      selected: "OpportunityCandidateList",
      objectId: "",
      candidateId: "",
      tab: '',
    };
  },
  mounted() {
    let self = this;
    self.showTab = 'active';
    store.commit("showLoader", false);
    self.jobTitle = localStorage.getItem('jobTitle');
    serverBus.$on("interviewParams", function (object) {
      self.objectId = object?.objectId;
      self.candidateId = object.candidateId ?? object.email;
      localStorage.setItem('candidateName', object.candidate);
      // self.interviewScheduleTab = true;
      self.selected = object.tab;
    });

  },
  methods: {
    backToList(userId) {
      if (userId != null) {
        this.$router.push({path: "/profile/all/" + userId});
      }
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>

