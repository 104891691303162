<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">

            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'OpportunityOutSystemCandidates',self.opportunityId)">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>

              </div>
            </CCol>
          </div>
        </CCardHeader>

        <CCardBody>


          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'OpportunityOutSystemCandidates',self.opportunityId)">
            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.recruitments.button.view_interviews"
                  v-on:click="interviewList(data.row)"
                  v-if="checkPermission('list-opportunity')"
              >
                <i class="fas fa-file"></i>
              </CButton>

            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  Recruitments
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import {serverBus} from "../../../main";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "OpportunityOutSystemCandidateList",
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      selectedColumns: [],
      iAmUser: "",
      userId: "",
      listUrl: "",
      list: [],
      experienceList: [],
      commissionTypeList: [],
      workTypeList: [],
      availabilityList: [],
      currencyList: [],
      jobTypeList: [],
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      jobData: [],
      requirement: [],
      worktype: [],
      activePage: 1,
      module: Recruitments,
      filter: {},
      recruitments: {},
      columns: [
        "company",
        "firstName",
        "lastName",
        "email",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          company: this.$lang.recruitments.table.candidates.company,
          firstName: this.$lang.recruitments.table.candidates.firstName,
          lastName: this.$lang.recruitments.table.candidates.lastName,
          email: this.$lang.recruitments.table.candidates.email,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [
          "firstName",
        ],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = "/opportunities/candidates/out-system/";
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false);
    self.opportunityId = this.$route.params.id;
    self.listUrl = this.listUrl + self.opportunityId;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    setAlert(value) {
      this.showAlert = value;
    },
    interviewList(row) {
      const interviewParams = {
        objectId: row.objectId,
        candidateId: row.id,
        candidate: row.firstName,
        'tab': 'OutSystemCandidateInterviewScheduleList'
      }
      // console.log(interviewParams);
      serverBus.$emit("interviewParams", interviewParams);
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
